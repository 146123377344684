import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ContactHero from "../components/contactHero/contactHero";
import ContactForm from "../components/contactForm/contactForm";

const ContactPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Contact Us" />
      <ContactHero data={data.wpPage.contactHero.contactHero} />
      <div className="contact-contact-form">
        <div className="container">
          <ContactForm />
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "contact" }) {
      contactHero {
        contactHero {
          title
          content
          wechatQr {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          redBookQr {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          linkedinLink
          instagramLink
          twitterLink
        content2
 }
      }
    }
  }
`;
